"use client";
import Image from "next/image";
import Link from "next/link";

export default function About() {
  return (
    <div className="row " data-wow-delay="0.5s">
      {/* wow fadeInUp */}
      <div className="col-lg-6 mb-md-60">
        <div className="position-relative">
          {/* Image */}
          <div className="position-relative overflow-hidden">
            <Image
              width={960}
              height={539}
              // src="/assets/images/about-image.jpg"
              src="/assets/images/img/about/aboutus_mission.webp"
              className="image-fullwidth relative"
              alt="aboutus Mission/Vission Image"
            />
          </div>
          {/* End Image */}
          {/* Decorative Waves */}
          <div
            className="decoration-1 d-none d-sm-block"
            data-rellax-y=""
            data-rellax-speed={1}
            data-rellax-percentage="0.1"
          >
            <Image
              width="159"
              height="74"
              src="/assets/images/decoration-1.svg"
              className="svg-shape"
              alt=""
            />
          </div>
          {/* End Decorative Waves */}
        </div>
      </div>
      <div className="col-lg-6 col-xl-5 offset-xl-1">
        <h4 className="h5">Our Mission</h4>
        <p className="text-gray">
          Our mission is to empower businesses with <Link href="/solutions/it-solutions" >innovative technology solutions</Link> that streamline operations, boost productivity, and foster growth. We aim to be a catalyst for positive change, helping our clients navigate the complexities of the modern business environment with confidence.
        </p>
        <h4 className="h5">Our Vision</h4>
        <p className="text-gray">
          {/* Our vision is to be the leading provider of solutions that not only meet today’s challenges but also anticipate tomorrow’s needs, driving sustainable success for our clients. */}
          We envision a future where all businesses thrive with <Link href="/solutions/customer-service-solutions" >cutting-edge solutions</Link>. Our goal is to be the leading provider of solutions that not only address today’s challenges but also anticipate tomorrow’s needs, ensuring sustainable success for our clients.
        </p>
      </div>
    </div>
  );
}
